import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import { FieldLabel } from './field-label'
import { FieldResponse } from './field-response'
import { Select } from './select'

export function SelectField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })
  const translate = Herz.I18n.useTranslate(() => [field.label], [field.label])

  return (
    <Common.Box display="flex" flexBasis="100%" flexDirection="column">
      {!props.hideLabel && translate(field.label) && (
        <FieldLabel
          htmlFor={field.name}
          fontColor={props.fontColor}
          disabled={props.disabled}
        >
          {translate(field.label)}
        </FieldLabel>
      )}
      <Select
        autoFocus={props.autoFocus}
        contentTranslated={props.contentTranslated}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        name={field.name}
        options={props.options}
        suppressVisualFeedback={props.suppressVisualFeedback}
        valid={R.isEmpty(field.errors)}
        visited={
          !R.includes(field.status, [
            Forms.FieldStatus.PRISTINE,
            Forms.FieldStatus.ABSENT,
          ])
        }
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />
      {!props.suppressVisualFeedback ? (
        <FieldResponse scope={props.scope} />
      ) : null}
    </Common.Box>
  )
}

SelectField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentTranslated: PropTypes.bool,
  fontColor: PropTypes.string,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
}
