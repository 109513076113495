import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'

function borderColor(props) {
  if (props.disabled) {
    return 'inputDisabled'
  }

  if (props.focused) {
    return 'secondary'
  }

  if (props.valid) {
    return 'success'
  } else if (props.invalid) {
    return 'danger'
  } else {
    return 'currentColor'
  }
}

function hover(props) {
  if (props.valid || props.invalid || props.disabled || props.focused) {
    return null
  } else {
    return 'inputHover'
  }
}

const Wrapper = styled.label`
  ${props =>
    css({
      alignItems: 'center',
      display: 'flex',
      color: props.focused ? 'secondary' : 'input',
      backgroundColor: props.disabled ? 'inputDisabled' : 'static-white',
      border: '1px solid',
      borderColor: borderColor(props),
      borderRadius: 0,
      boxShadow: props.focused ? 0 : 'none',
      fontSize: 3,
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      py: '0.475em',
      '&:hover': {
        borderColor: hover(props),
      },
    })}

  &:disabled {
    cursor: not-allowed;
  }
`

const StyledSelect = styled.select`
  ${css({
    backgroundColor: 'static-white',
    border: 0,
    color: 'input-text',
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: 'body',
    fontSize: 3,
    mr: '-30px',
    paddingBottom: '0.35em',
    paddingLeft: '15px',
    paddingTop: '0.35em',
    width: '100%',
    '&:focus': { outline: 'none' },
    appearance: 'none',
  })}

  &:disabled {
    cursor: not-allowed;
    ${css({
      backgroundColor: 'inputDisabled',
      color: 'gray',
    })}
  }

  // hack to change the background-color that's added by autofill
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${t.color('static-white')} inset !important;
  }
`

const CaretDown = styled.div`
  font-size: 1.5em;
  color: ${t.color('gray')};
  pointer-events: none;
  width: 30px;
`

export function Select(props) {
  const translate = Herz.I18n.useTranslate(
    () => [...props.options.map(option => option.label)],
    [props.options]
  )
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)

  const value = props.value && props.value.toString()
  const valid = props.valid && !props.suppressVisualFeedback
  const invalid = !props.valid && props.visited && !props.suppressVisualFeedback

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
      /**
       * Because of custom focus handling, re-render of component happens before the
       * onChange event. This causes the value of the field to fallback to the initial value
       * on auto-complete/fill events.
       * By calling the onChange with the value of the component before it's focused we ensure it's the correct value.
       * The side-effect though, is that onChange is fired twice.
       */
      props.onChange({ target: inputRef.current.control })
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper
      empty={R.isEmpty(props.value)}
      valid={valid}
      invalid={invalid}
      focused={focused}
      disabled={props.disabled}
      ref={inputRef}
    >
      <StyledSelect
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        value={value}
        data-testid={`${props.name}.select-field`}
        onBlur={handleBlur}
        onChange={props.onChange}
        onFocus={() => setFocused(true)}
      >
        {props.options &&
          props.options.map(option => (
            <option key={option.value} value={option.value}>
              {props.contentTranslated ? option.label : translate(option.label)}
            </option>
          ))}
      </StyledSelect>
      {!props.disabled ? (
        <CaretDown>
          <Icons.ExpandMore />
        </CaretDown>
      ) : null}
    </Wrapper>
  )
}

Select.defaultProps = {
  onBlur: () => {},
}

Select.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentTranslated: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  suppressVisualFeedback: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}
